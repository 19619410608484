import { EDITOR_WIDTH_BODY } from '../../../../utils/consts';
import { uuid } from '../../EditorDocum/entities/utils';

const findClassName = (value: string) => {
  if (value.includes('template')) return true;
  if (['definition', 'def-ico-block', 'definition-icon', 'toggle__title'].includes(value)) return true;
  return false;
};

export const convertValueToPercentage = (value: string, denominator: number) => {
  if (value && value.includes('px')) {
    const num = Number(value.replace(/[^0-9.]/g, ''));
    const result = num / denominator;
    if (result > 1) {
      return '100%';
    } else {
      return `${Math.ceil(result * 100)}%`;
    }
  }

  return value;
};

export const pastePreprocess =
  ({ maxLength }: { maxLength?: number }) =>
  (plugin: any, args: any) => {
    const text = args.content.replaceAll('border: 1px solid initial;', 'border: 1px solid black;');

    const div = document.createElement('div');
    div.insertAdjacentHTML('afterbegin', text);

    // If a limit on the number of characters is added in the editor, then the number is checked
    if (maxLength) {
      const prevText = args.target?.getContent({ format: 'text' });
      const fullText = prevText + div.innerText;
      // If the total number of characters exceeds the limit, the content insertion is canceled
      if (fullText.length > maxLength) {
        args.content = '';
        return args;
      }
    }

    // Delete all attributes in elements
    div.querySelectorAll('*').forEach((el) => {
      // Skip the following items
      if (
        ['table', 'thead', 'tbody', 'tr', 'td', 'th', 'img', 'iframe', 'a', 'div', 'svg', 'path'].includes(
          el.nodeName.toLowerCase()
        )
      )
        return null;
      // Skip an element that belongs to templates
      if (Array.from(el.classList).find(findClassName)) return null;

      while (el.attributes.length > 0) {
        el.removeAttribute(el.attributes[0].name);
      }
    });

    div.querySelectorAll('table').forEach((el) => {
      const width = el.getAttribute('width');
      if (width) {
        el.setAttribute('width', convertValueToPercentage(width, EDITOR_WIDTH_BODY));
      }

      const styleWidth = el.style.width;
      if (styleWidth) {
        el.style.width = convertValueToPercentage(styleWidth, EDITOR_WIDTH_BODY);
      }
    });

    // Replacing "div" elements with "p"
    for (let i = 0; i < div.childElementCount; i++) {
      const el = div.children[i];
      const arrClassList = Array.from(el.classList);

      // Skip an element that belongs to templates and iframe tinymce
      if (
        el &&
        !['template', 'toggle', 'tiny-pageembed'].find((item) =>
          arrClassList.find((itemClass) => itemClass.includes(item))
        )
      ) {
        if (
          'div'.includes(el.nodeName.toLowerCase()) &&
          !['template', 'toggle', 'tiny-pageembed'].find((item) =>
            arrClassList.find((itemClass) => itemClass.includes(item))
          )
        ) {
          const p = document.createElement('p');
          p.innerHTML = el.innerHTML.replace(/<\/?div[^>]*>/g, '');
          el.parentNode?.insertBefore(p, el);
          el.parentNode?.removeChild(el);
        }
      }
    }

    // Addition to all paragraphs id
    div.querySelectorAll('p, h2, h3, li').forEach((value) => value.setAttribute('id', uuid()));

    // Validation of content insertion into template elements
    const node = args.target?.selection?.getNode();
    if (
      ['templates-2__body-title', 'templates-2__user-name', 'templates-2__user-pos'].find((item) =>
        node?.classList?.contains(item)
      )
    ) {
      args.content = div.textContent;
    } else if (node?.classList?.contains('templates-2__body-text')) {
      // If the content is pasted into the body of the template, we add a class to each paragraph
      div.querySelectorAll('p').forEach((value) => {
        value.classList.add('templates-2__body-text');
      });
      args.content = div.innerHTML;
    } else {
      args.content = div.innerHTML;
    }
  };
