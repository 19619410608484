import { Editor } from '@tinymce/tinymce-react';
import { callApi } from '../../../../utils/callApi';
import { configSites } from '../../../../config';

interface IOptions {
  id?: string;
  siteId: number;
  categoryId: number;
  isCustom?: boolean;
}

const ACCEPT =
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/*';

function uploadFile(editor: Editor['editor'], options: IOptions) {
  if (!editor) return null;

  const { id, siteId, categoryId, isCustom } = options;

  const node = editor.$('a[data-mce-selected="inline-boundary"]')[0];
  if (!node || node.nodeName.toLowerCase() !== 'a') return null;
  const inputFile = document.createElement('input');
  inputFile.setAttribute('type', 'file');
  inputFile.accept = ACCEPT + (isCustom ? ',.pptx' : '');
  document.body.appendChild(inputFile);
  inputFile.click();
  inputFile.onchange = (e) => {
    //@ts-ignore
    const file = e.target?.files[0];
    if (file) {
      const fd = new FormData();
      fd.append('siteId', String(siteId));
      fd.append('categoryId', String(categoryId));
      fd.append('id', id || '');
      fd.append('file', file);

      callApi({
        method: 'post',
        data: fd,
        isFormData: true,
        path: '/upload/file',
      })
        .then((path: string) => {
          editor.$(node).removeAttr('data-mce-href');
          editor.$(node).attr('href', `${configSites[siteId].url}/${path}`);
          editor.$(node).attr('download', file.name);

          const type = path.substring(path.lastIndexOf('.') + 1);
          let mimetype = 'none';
          if (type.includes('pdf')) mimetype = 'pdf';
          if (type.includes('doc') || type.includes('word')) mimetype = 'doc';
          if (type.includes('xls') || type.includes('excel')) mimetype = 'xls';
          if (type.includes('png')) mimetype = 'png';
          if (type.includes('jpg') || type.includes('jpeg')) mimetype = 'jpg';
          if (type.includes('pptx')) mimetype = 'pptx';

          editor.$(node).attr('class', `link-download link-type-export file-${mimetype}`);

          if (isCustom) {
            node.textContent = file.name.split('.')[0];
          }
        })
        .catch((err) => console.log(err));
    }
  };
  inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
}

export const uploadcare = (editor: Editor['editor'], options: IOptions) => () => {
  if (!editor) return null;

  const { id } = options || {};

  if (id) {
    uploadFile(editor, options);
  } else {
    editor.windowManager.open({
      title: 'Відсутній ID документа!',
      onSubmit(api) {
        uploadFile(editor, options);
        api.close();
      },
      buttons: [
        {
          type: 'submit',
          text: 'Продовжити',
          primary: true,
          align: 'start',
        },
        {
          type: 'cancel',
          text: 'Скасувати',
          align: 'end',
        },
      ],
      body: {
        type: 'panel',
        items: [
          {
            type: 'htmlpanel',
            html: 'Заповніть обов`язкові поля та збережіть документ!',
          },
        ],
      },
    });
  }
};
