export const ROW_PAGE = 20;
export const LIMIT = 25;
export const LIMIT_BLOCK = 100;
export const TIMEOUT_REQ = 1000;
export const APPROVED = {
  PUBLISH: 2,
  CREATE: 1,
};

export const EDITOR_WIDTH_BODY = 710;

export const colorsStatus: { [x: string]: string } = {
  '2': 'green',
  '1': '#3f51b5',
};

export const monthsOptions = [
  { label: 'Грудень', value: 'Грудень', id: 0 },
  { label: 'Січень', value: 'Січень', id: 1 },
  { label: 'Лютий', value: 'Лютий', id: 2 },
  { label: 'Березень', value: 'Березень', id: 3 },
  { label: 'Квітень', value: 'Квітень', id: 4 },
  { label: 'Травень', value: 'Травень', id: 5 },
  { label: 'Червень', value: 'Червень', id: 6 },
  { label: 'Липень', value: 'Липень', id: 7 },
  { label: 'Серпень', value: 'Серпень', id: 8 },
  { label: 'Вересень', value: 'Вересень', id: 9 },
  { label: 'Жовтень', value: 'Жовтень', id: 10 },
  { label: 'Листопад', value: 'Листопад', id: 11 },
];

export const categoriesSelect: { value: string; name: string; disabled?: boolean }[] = [
  { value: '1', name: 'Консультації', disabled: false },
  { value: '2', name: 'Форми', disabled: false },
  { value: '3', name: 'Відео', disabled: false },
  { value: '8', name: 'Довідники', disabled: false },
];

export const defaultUsavedFlags = {
  unsavedTitle: false,
  unsavedBody: false,
  unsavedDesc: false,
  unsavedPublicationAt: false,
  unsavedAuthorFirst: false,
  unsavedAuthorSec: false,
  unsavedAuthorThir: false,
  unsavedIsNews: false,
  unsavedMainRubric: false,
  unsavedRubrics: false,
};

export enum CATEGORIES {
  CONSULTATIONS = 1,
  FORMS,
  VIDEO,
  SERVICES,
  NPD,
  CATALOGUES = 8,
  FACTOIDS,
  TEMPLATES,
  DEFINITIONS,
  MAIN_IN_MONTH,
}

export enum BLOCK_TYPES {
  MAIN = 1,
  CALCULATERS,
  NAVIGATORS,
}

export const categoryName: { [x: string]: string } = {
  [CATEGORIES.CONSULTATIONS]: 'recommendations',
  [CATEGORIES.FORMS]: 'forms',
  [CATEGORIES.VIDEO]: 'videos',
  [CATEGORIES.SERVICES]: 'services',
  [CATEGORIES.NPD]: 'laws',
  [CATEGORIES.CATALOGUES]: 'catalogues',
};

export enum Roles {
  REDACTOR = 1,
  HEAD,
  ADMIN,
}

export const Positions: Record<string, string> = {
  [Roles.REDACTOR]: 'Редактор',
  [Roles.HEAD]: 'Головред',
  [Roles.ADMIN]: 'Адмін',
};

export enum StatusNPD {
  EXPIRED,
  WILL_BE,
  VALID,
  PARTIALLY_INVALIDATED,
}
